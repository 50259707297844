<template>
  <div>
  	<div>
      <div class="flex items-center mt-2">
        <a-tabs type="card" @change="handlerModeChange" :activeKey="activeMode">
          <a-tab-pane :key="2" tab="产品销售曲线"></a-tab-pane>
          <a-tab-pane :key="0" tab="全部商品销售统计"></a-tab-pane>
          <a-tab-pane :key="1" tab="全部月度销售汇总"></a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <div class="analy-tips">
    	<span>* 涉及到第三方平台（电商/外卖/收银等）订单的，只能统计到同步到小程序系统的订单数据（例如天猫/美团的非蛋糕是统计不到的）</span>
    </div>
    <template v-if="activeMode == 0">
	    <div class="search-bar flex justify-between items-center">
	      <a-form-model layout="inline" ref="searchRef">
	        <a-form-model-item prop="outlets_city_adcode" label="选择城市">
	          <a-select style="width:140px;" placeholder="请选择城市"
	               :disabled="loading_goods"
	               v-model="searchForm.outlets_city_adcode"
	               @change="changeCity">
	            <a-select-option v-if="!isCityManage" value>不限</a-select-option>
	            <a-select-option v-for="(city, index) in manageCityList"
	                 :value="city.adcode"
	                 :key="index">{{city.name}}</a-select-option>
	          </a-select>
	        </a-form-model-item>
	        <a-form-model-item prop="order_source" label="订单来源">
            <a-select placeholder="请选择订单来源" style="width:160px;"
            		:disabled="loading_goods"
                v-model="searchForm.order_source">
              <a-select-option v-for="(item, index) in orderSourceOptions"
                   :key="index"
                   :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
	        <a-form-model-item prop="search_date" label="日期范围">
	          <a-range-picker
	            style="width:300px"
            	:disabled="loading_goods"
	            v-model="search_date"
	            valueFormat="YYYY-MM-DD"
	          />
	        </a-form-model-item>
	        <a-form-model-item>
	          <a-button type="primary" :loading="loading_goods" @click="handlerSearch">搜索</a-button>
	        </a-form-model-item>
	      </a-form-model>
	    </div>

	    <div>
	      <div class="flex justify-between items-center ml-2 mr-2 mt-2 mb-2">
	        <a-radio-group name="activeKey" v-model="activeKey">
	          <a-radio :value="0">按SKU统计</a-radio>
	          <a-radio :value="1">按产品统计</a-radio>
	        </a-radio-group>
	        <a-button v-if="sku_tableData && sku_tableData.length" 
	        	@click="handlerDownload">下载表格</a-button>
	      </div>
	    </div>

    	<base-table v-if="activeKey == 0"
				rowKey="sku_number"
				:columnsData="sku_columns"
				:tableData="sku_tableData"
			></base-table>
			<base-table v-if="activeKey == 1"
				rowKey="goods_id"
				:columnsData="good_columns"
				:tableData="good_tableData"
			></base-table>
    </template>

    <template v-if="activeMode == 1">
    	<div class="search-bar flex justify-between items-center">
	      <a-form-model layout="inline" ref="searchRef2">
	        <a-form-model-item prop="outlets_city_adcode" label="选择城市">
	          <a-select style="width:140px;" placeholder="请选择城市"
	               v-model="searchForm2.outlets_city_adcode" 
	               :disabled="loading_month"
	               @change="changeCity">
	            <a-select-option v-if="!isCityManage" value>不限</a-select-option>
	            <a-select-option v-for="(city, index) in manageCityList"
	                 :value="city.adcode"
	                 :key="index">{{city.name}}</a-select-option>
	          </a-select>
	        </a-form-model-item>
	        <a-form-model-item prop="order_source" label="订单来源">
            <a-select placeholder="请选择订单来源" style="width:160px;"
                 v-model="searchForm2.order_source">
              <a-select-option v-for="(item, index) in orderSourceOptions"
                   :key="index"
                   :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
	        <a-form-model-item prop="search_month" label="月份范围">
	          <a-range-picker style="width:300px"
	          	:disabled="loading_month"
				      format="YYYY-MM"
				      valueFormat="YYYY-MM"
				      v-model="search_month"
				      :mode="monthMode"
				      :open="monthPickerShow"
				      @openChange="handlerMonthOpen"
				      @panelChange="handlerMonthPanel"
				    />
	        </a-form-model-item>
	        <a-form-model-item>
	          <a-button type="primary" :loading="loading_month" 
	          	@click="handlerSearch2">统计</a-button>
	        </a-form-model-item>
	      </a-form-model>
	    </div>

	    <template v-if="!loading_month">
	    	<div class="flex justify-between items-center ml-2 mr-2 mt-2 mb-2">
	        <a-radio-group name="activeMonthKey" v-model="activeMonthKey">
	          <a-radio :value="0">看销量</a-radio>
	          <a-radio :value="1">看销售额</a-radio>
	        </a-radio-group>
	        <a-button v-if="month_columns_number && month_columns_number.length" 
	        	@click="handlerDownload2">下载表格</a-button>
	      </div>
	    	<base-table v-if="activeMonthKey == 0"
					rowKey="goods_id"
					:columnsData="month_columns_number"
					:tableData="month_tableData_number"
				></base-table>
	    	<base-table v-if="activeMonthKey == 1"
					rowKey="goods_id"
					:columnsData="month_columns_amount"
					:tableData="month_tableData_amount"
				></base-table>
	    </template>
			<div v-else class="no-data">
				{{load_month_msg}}
			</div>
    </template>

    <!-- 折线图统计 -->
    <template v-if="activeMode == 2">
	    <div class="goods-table flex">
	    	<!-- 左侧选商品 -->
	    	<div class="goods-left">
	    		<div class="search-item">
	    			<a-radio-group :disabled="loading_line" v-model="searchForm3.mode">
		          <a-radio :value="0">销售来源对比</a-radio>
		          <a-radio :value="1">产品对比</a-radio>
		        </a-radio-group>
	    		</div>
	    		<div class="search-item">
	    			<div class="label">城市:</div>
	    			<a-select style="flex:1;" placeholder="请选择城市"
	               v-model="searchForm3.outlets_city_adcode" 
	               :disabled="loading_line"
	               @change="changeCity">
	            <a-select-option v-if="!isCityManage" value>不限</a-select-option>
	            <a-select-option v-for="(city, index) in manageCityList"
	                 :value="city.adcode"
	                 :key="index">{{city.name}}</a-select-option>
	          </a-select>
	    		</div>
	    		<div class="search-item">
	    			<div class="label">时间:</div>
	    			<a-range-picker style="flex:1;"
	    				:disabled="loading_line"
	            v-model="search_date3"
	            valueFormat="YYYY-MM-DD"
	          />
	    		</div>
	    		<template v-if="searchForm3.mode == 1">
	    			<div class="search-item">
		    			<div class="label">来源:</div>
		    			<a-select style="flex:1;" placeholder="请选择订单来源"
	    						:disabled="loading_line"
	    						v-model="searchForm3.order_source">
	              <a-select-option v-for="(item, index) in orderSourceOptions"
	                   :key="index"
	                   :value="item.id">{{item.name}}</a-select-option>
	            </a-select>
		    		</div>
		    		<div class="search-item">
		    			<div class="label">商品:</div>
			    		<a-select style="flex:1; overflow: hidden;" placeholder="输入商品名"
		                  v-model="search_goods_ids"
		                  show-search allowClear
	    								:disabled="loading_line"
		                  mode="multiple"
		                  :default-active-first-option="false"
		                  :show-arrow="false"
		                  :filter-option="false"
		                  :not-found-content="null"
		                  @search="handlerSuggestGoods"
		                  @change="handlerGoodsChanged">
		            <a-select-option v-for="item in suggestGoodsList"
		                     :key="item.goods_id"
		                     :value="item.goods_id">{{ item.goods_name }}</a-select-option>
		          </a-select>
		    		</div>
	    		</template>
	    		<template v-else>
	    			<div class="search-item">
		    			<div class="label">来源:</div>
	            <a-select style="flex:1; overflow: hidden;" placeholder="输入订单来源"
		                  v-model="search_source_ids"
		                  allowClear mode="multiple"
		                  :disabled="loading_line"
		                  :default-active-first-option="false"
		                  :show-arrow="false"
		                  :filter-option="false"
		                  :not-found-content="null">
		            <a-select-option v-for="(item, index) in orderSourceOptions"
	                   :key="index"
	                   :value="item.id">{{item.name}}</a-select-option>
		          </a-select>
		    		</div>
		    		<div class="search-item">
		    			<div class="label">商品:</div>
			    		<a-select style="flex:1; overflow: hidden;" placeholder="输入商品名"
		                  v-model="searchForm3.goods_id"
		                  show-search allowClear
		                  :disabled="loading_line"
		                  :default-active-first-option="false"
		                  :show-arrow="false"
		                  :filter-option="false"
		                  :not-found-content="null"
		                  @search="handlerSuggestGoods">
		            <a-select-option v-for="item in suggestGoodsList"
		                     :key="item.goods_id"
		                     :value="item.goods_id">{{ item.goods_name }}</a-select-option>
		          </a-select>
		    		</div>
	    		</template>
	    		<div class="search-item">
	    			<div></div>
	          <a-button type="primary" :loading="loading_line" @click="handlerSearch3">查看</a-button>
	    		</div>
	    	</div>
	    	<!-- 右侧曲线 -->
	    	<div style="flex:1;padding-top:20px;">
	    		<!-- <canvas id="line-chart"></canvas> -->
	    		<div ref="chart" style="marg width: 100%; height: 400px;"></div>
	    	</div>
	    </div>
    </template>
		
  </div>
</template>

<script>

import { 
	getGoodsSaled,
	getGoodsDailySaled
} from '@/api/regional/analysis'
import { getGoodsList } from "@/api/goods";
import { 
	isCityManage,
  getMarketCityList,
} from '@/utils/authData.js'
import { formatEnMoney } from "@/utils/index.js"
import { 
	formatGoodsType, 
	orderSourceList 
} from "@/utils/type"
import moment from "moment"
import * as echarts from 'echarts';

export default {
  data() {
    return {
    	moment,

    	searchForm: {
				outlets_city_adcode: undefined,
				order_source: -1,
    	},
    	search_date: [],

    	searchForm2: {
				outlets_city_adcode: undefined,
				order_source: -1,
    	},
			search_month: [],
			month_selected: [],
			monthMode: ["month", "month"],
			monthPickerShow: false,

    	searchForm3: {
				outlets_city_adcode: undefined,
				mode: 0,
				order_source: -1,
				goods_id: undefined,
    	},
    	search_source_ids: [],
    	search_goods_ids: [],
    	search_goods_list: [],
    	search_date3: [],
      suggestGoodsList: [],
      timerSuggestGoods: null,

    	orderSourceOptions: [{
          id: 0, 
          name: "全部" 
      },{
          id: -1, 
          name: "小程序+官网" 
      }].concat(orderSourceList),

			// cityList: [],
			isCityManage: isCityManage(),
			manageCityList: [],

			activeMode: 2,

			activeKey: 0,
      good_columns:[
      	{
					title: "产品ID",
					dataIndex: "goods_id",
					align: "center",
					width: 80,
				},
				{
					title: "产品属性",
					dataIndex: "goods_type_name",
					align: "center",
					width: 90,
        },
        {
					title: "产品名",
					dataIndex: "goods_name",
					align: "center",
				},
        {
					title: "产品销售总量",
					dataIndex: "total_number",
					align: "center",
					width: 140,
				},
        {
					title: "产品销售总额(元)",
					dataIndex: "total_amount",
					align: "center",
					width: 140,
					slots: {
						customRender: "total_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				}
      ],
      good_tableData: [],
      loading_goods: false,

      sku_columns:[
        {
					title: "产品ID",
					dataIndex: "goods_id",
					align: "center",
					width: 80,
				},
				{
					title: "SKU编码",
					dataIndex: "sku_number",
					align: "center",
					width: 90,
				},
        {
					title: "产品属性",
					dataIndex: "goods_type_name",
					align: "center",
					width: 90,
        },
				{
					title: "产品名",
					dataIndex: "goods_name",
					align: "center",
					width: "40%",
				},
        {
					title: "口味",
					dataIndex: "flavor_name",
					align: "center",
					width: "30%",
				},
        {
					title: "尺寸",
					dataIndex: "specification_name",
					align: "center",
					width: "30%",
				},
        {
					title: "SKU销售总量",
					dataIndex: "total_number",
					align: "center",
					width: 140,
				},
        {
					title: "SKU销售总额(元)",
					dataIndex: "total_amount",
					align: "center",
					width: 140,
					slots: {
						customRender: "total_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				}
      ],
			sku_tableData: [],

			activeMonthKey: 0,
			month_columns_number: [],
			month_columns_amount: [],
			month_columns:[
      	{
					title: "产品ID",
					dataIndex: "goods_id",
					align: "center",
					width: 80,
				},
        {
					title: "产品属性",
					dataIndex: "goods_type_name",
					align: "center",
					width: 90,
        },
        {
					title: "产品名",
					dataIndex: "goods_name",
					align: "center",
				},
      ],
      month_tableData_number: [],
      month_tableData_amount: [],
      loading_month: false,
      load_month_msg: "",

      loading_line: false,

      myEchart: null,
    }
  },
  async mounted() {
  	this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]

  	this.manageCityList = await getMarketCityList()
    // 如果是城市市场账号
    if(this.isCityManage) {
      if(this.manageCityList.length == 1){
        this.searchForm.outlets_city_adcode = this.manageCityList[0].adcode
        this.searchForm2.outlets_city_adcode = this.manageCityList[0].adcode
      }
    }

    // if(this.isCityManage){
    //   if(this.outlets_city_adcode){
    //     this.handlerSearch()
    //   }
    // }else{
    // 	this.outlets_city_adcode = ""
    //   this.handlerSearch()
    // }
  },
  methods: {
    changeCity(){
			// this.handlerSearch()
    },

    handlerModeChange(e){
    	this.activeMode = e
    },
    // handlerTypeChange(e){
    // 	this.activeKey = e
    // },

    handlerMonthOpen(val){
    	this.monthPickerShow = val
    	if(val){
    		this.month_selected = []
    	}
    },
    handlerMonthPanel(e, mode){
    	if(mode[0] == "date"){
    		this.search_month[0] = moment(e[0]).format("YYYY-MM")
    		this.month_selected[0] = true
    	}
    	if(mode[1] == "date"){
    		this.search_month[1] = moment(e[1]).format("YYYY-MM")
    		this.month_selected[1] = true
    	}
    	if(this.search_month.length == 2 && this.month_selected[0] && this.month_selected[1]){
    		this.monthPickerShow = false
    	}
    },

    // 搜索
    async handlerSearch() {
			if(this.isCityManage) {
        if(!this.searchForm.outlets_city_adcode){
          this.$message.info("请选择城市")
          return
        }
      }
      
      if(this.search_date.length < 2){
      	this.$message.warning("请选择时间")
        return
      }
      if(this.search_date.length == 2){
        if(new Date(this.search_date[1]).getTime() - new Date(this.search_date[0]).getTime() > 31 * 24 * 3600 * 1000){
          this.$message.warning("时间跨度不能超过一个月")
          return
        }
      }
      // 请求数据
      this.sku_tableData = []
      this.loading_goods = true
      const { data, code } = await getGoodsSaled({
      	time_start: this.search_date[0],
      	time_end:   this.search_date[1],
      	outlets_city_adcode: this.searchForm.outlets_city_adcode,
      	order_source: this.searchForm.order_source,
      });
      this.loading_goods = false
      if (code === 0) {
      	data.list.map(el=>{
        	el.goods_type_name = formatGoodsType(el.goods_type)
        }).sort((a,b)=>{
        	const delta = Number(a.total_amount) - Number(b.total_amount)
        	return delta > 0 ? -1 : (delta < 0 ? 1 : 0)
        })
        this.sku_tableData = data.list
        let good_list = []
        data.list.map(el=>{
        	let good = good_list.find(gd=>gd.goods_id == el.goods_id)
        	if(good){
        		good.total_number = good.total_number + el.total_number
        		good.total_amount = good.total_amount + Number(el.total_amount)
        	}else{
        		good_list.push({
        			goods_id: 	el.goods_id,
        			goods_type_name: el.goods_type_name,
        			goods_name: el.goods_name,
        			total_number: el.total_number,
        			total_amount: Number(el.total_amount),
        		})
        	}
        })
        good_list.sort((a,b)=>{
        	const delta = Number(a.total_amount) - Number(b.total_amount)
        	return delta > 0 ? -1 : (delta < 0 ? 1 : 0)
        })
        this.good_tableData = good_list
      }
    },
    // 月度统计
    async handlerSearch2() {
			if(this.isCityManage) {
        if(!this.searchForm2.outlets_city_adcode){
          this.$message.info("请选择城市")
          return
        }
      }

      if(this.search_month.length < 2){
      	this.$message.warning("请选择月份")
        return
      }
      if(this.search_month.length == 2){
        if(new Date(this.search_month[1]).getTime() - new Date(this.search_month[0]).getTime() > 366 * 24 * 3600 * 1000){
          this.$message.warning("时间跨度不能超过1年")
          return
        }
      }

      let start_year 	= parseInt(this.search_month[0].split("-")[0])
      let start_month = parseInt(this.search_month[0].split("-")[1])
      let end_year 		= parseInt(this.search_month[1].split("-")[0])
      let end_month 	= parseInt(this.search_month[1].split("-")[1])

      let list = []
      let year  = start_year
      let month = start_month

      while(year < end_year || (year==end_year && month<=end_month)){
      	list.push({
      		year: year,
      		month: month,
      		ym_str: year+"-"+(month<10?("0"+month):month),
      	})
      	if(month < 12){
      		month++
      	}else{
      		year++
      		month = 1
      	}
      }

      this.month_columns_number = [].concat(this.month_columns).concat(list.map(m=>{
      	return {
					title: m.ym_str,
					dataIndex: m.ym_str+"_number",
					align: "center",
					width: 90,
				}
      }))
      this.month_columns_amount = [].concat(this.month_columns).concat(list.map(m=>{
      	return {
					title: m.ym_str,
					dataIndex: m.ym_str+"_amount",
					align: "center",
					width: 110,
				}
      }))
      this.month_tableData_number = []
      this.month_tableData_amount = []

      // 串行加载（每月份请求一次）
			this.load_month_msg = "计算中"
			this.loading_month = true
      let result = await this.reqMonth([].concat(list), {
      	outlets_city_adcode: this.searchForm2.outlets_city_adcode,
      	order_source: this.searchForm2.order_source,
      })
      result.map(el=>{
      	list.map(m=>{
      		el[m.ym_str+"_number"] = el[m.ym_str+"_number"] || 0
      		el[m.ym_str+"_amount"] = el[m.ym_str+"_amount"] || 0
      	})
      })

      this.month_tableData_number = [].concat(result).sort((a,b)=>{
      	return b.total_number - a.total_number
      })
      this.month_tableData_amount = [].concat(result).sort((a,b)=>{
      	return b.total_amount - a.total_amount
      })
			this.loading_month = false
    },

    // 折线图
    async handlerSearch3(){
    	if(this.isCityManage) {
        if(!this.searchForm3.outlets_city_adcode){
          this.$message.info("请选择城市")
          return
        }
      }
      
      if(this.search_date3.length < 2){
      	this.$message.warning("请选择时间")
        return
      }
      if(new Date(this.search_date3[1]).getTime() - new Date(this.search_date3[0]).getTime() > 31 * 24 * 3600 * 1000){
        this.$message.warning("时间跨度不能超过一个月")
        return
      }

      const params_list = []
    	const date = new Date(this.search_date3[0].replace("-", "/"))
    	const endTime = new Date(this.search_date3[1].replace("-", "/")).getTime()
    	const date_list = []
    	while(date.getTime() <= endTime){
    		date_list.push(this.formatDate(date))
    		date.setTime(date.getTime() + 24*3600*1000)
    	}

      if(this.searchForm3.mode == 1){
				if(!this.search_goods_list.length){
	      	this.$message.warning("请选择商品")
	        return
	      }
	      this.search_goods_list.map(goods=>{
	      	params_list.push({
	      		order_source: this.searchForm3.order_source,
	      		outlets_city_adcode: this.searchForm3.outlets_city_adcode,
		      	time_start: this.search_date3[0],
		      	time_end:   this.search_date3[1],
	      		goods_id: 	goods.goods_id,
	      		name: 			goods.goods_name,
	      	})
	      })
      }else{
      	if(!this.searchForm3.goods_id){
	      	this.$message.warning("请选择商品")
	        return
	      }
	      this.search_source_ids.map(sid=>{
	      	const source = this.orderSourceOptions.find(el=>el.id==sid)
	      	params_list.push({
	      		order_source: sid,
	      		outlets_city_adcode: this.searchForm3.outlets_city_adcode,
		      	time_start: this.search_date3[0],
		      	time_end:   this.search_date3[1],
	      		goods_id: 	this.searchForm3.goods_id,
	      		name: 			source ? source.name : sid,
	      	})
	      })
      }

      this.loading_line = true
      for(let i = 0; i<params_list.length; i++){
      	const item = params_list[i]
      	const { data, code } = await getGoodsDailySaled({
      		order_source: 			 item.order_source,
      		outlets_city_adcode: item.outlets_city_adcode,
      		time_start: item.time_start,
      		time_end: 	item.time_end,
      		goods_id: 	item.goods_id,
	      });
	      if(code == 0){
		      item.result = date_list.map(date=>{
		      	const item = data.list.find(el=>el.date==date)
		      	return {
		      		key: date,
		      		value: item ? item.number : 0,
		      	}
		      })
	      }
      }
      this.loading_line = false

      if(this.myEchart){
      	this.myEchart.clear()
      	this.myEchart = null
      }
    	const option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        legend: {
          data: params_list.map(el=>el.name)
        },
        xAxis: {
          // type: 'category',
          boundaryGap: false,
          data: date_list
        },
        yAxis: {
          type: 'value'
        },
        series: params_list.map(item=>{
        	return {
            type: 'line',
        		name: item.name,
            data: item.result.map(el=>el.value)
        	}
        })
      };
      this.myEchart = echarts.init(this.$refs.chart)
      this.myEchart.setOption(option);
    },

    async reqMonth(monthList, params, result){
    	if(monthList.length){
    		const item = monthList.shift()
    		let year = item.year
    		let month = item.month
    		let ym_str = item.ym_str

    		this.load_month_msg = "正在计算 "+ym_str+" ..."

    		const startDate = ym_str+"-01"

    		let end = ""
    		if(month < 12){
    			end = year + "-" + (month+1) + "-01"
    		}else{
    			end = (year+1) + "-01-01"
    		}
    		const date = new Date(end)
    					date.setTime(date.getTime()-24*3600*1000)
    		const endDate = moment(date).format("YYYY-MM-DD")

    		const { data, code } = await getGoodsSaled({
    			...params,
	      	time_start: startDate,
	      	time_end:   endDate,
	      });
	      if(code == 0){
	      	// sku合并成good
	      	const list = []
	      	data.list.map(el=>{
	      		const goods_id = el.goods_id
						const goods = list[goods_id] || {
							goods_id: 		el.goods_id,
							goods_name: 	el.goods_name,
							goods_type: 	el.goods_type,
							total_amount: 0,
							total_number: 0,
						}
						goods.total_amount += Number(el.total_amount)
						goods.total_number += el.total_number

						list[goods_id] = goods
	      	})
	      	// 每月good数据
	      	result = result || []
	      	Object.keys(list).map(gid=>{
	      		const m_goods = list[gid]
	      		let gd = result.find(e=>e.goods_id==m_goods.goods_id)
	      		if(!gd){
	      			gd = {
								goods_id: 		m_goods.goods_id,
								goods_name: 	m_goods.goods_name,
								goods_type_name: formatGoodsType(m_goods.goods_type),
								total_amount: 0,
								total_number: 0,
							}
							result.push(gd)
	      		}
	      		gd[ym_str+"_number"] = m_goods.total_number
	      		gd[ym_str+"_amount"] = formatEnMoney(m_goods.total_amount)
	      		gd.total_amount += m_goods.total_amount
	      		gd.total_number += m_goods.total_number
	      	})

	      	return await this.reqMonth(monthList, params, result)
	      }
    	}else{
    		result = result || []
    		return result
    	}
    },

    handlerDownload(){
			let list1 = []
			const head1 = []
			this.sku_columns.map(el=>{
				head1.push(el.title)
			})
			list1.push(head1)
			this.sku_tableData.map(item=>{
				let arr = []
				this.sku_columns.map(el=>{
					arr.push(item[el.dataIndex])
				})
				list1.push(arr)
			})

			let list2 = []
			const head2 = []
			this.good_columns.map(el=>{
				head2.push(el.title)
			})
			list2.push(head2)

			this.good_tableData.map(item=>{
				let arr = []
				this.good_columns.map(el=>{
					arr.push(item[el.dataIndex])
				})
				list2.push(arr)
			})
			
      const XLSX = require('xlsx')
      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list1), "按sku统计")
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list2), "按产品统计")

      XLSX.writeFile(wb, "商品销售统计.xlsx")
    },

    handlerDownload2(){
			let list1 = []
			const head1 = []
			this.month_columns_number.map(el=>{
				head1.push(el.title)
			})
			list1.push(head1)
			this.month_tableData_number.map(item=>{
				let arr = []
				this.month_columns_number.map(el=>{
					arr.push(item[el.dataIndex])
				})
				list1.push(arr)
			})

			let list2 = []
			const head2 = []
			this.month_columns_amount.map(el=>{
				head2.push(el.title)
			})
			list2.push(head2)

			this.month_tableData_amount.map(item=>{
				let arr = []
				this.month_columns_amount.map(el=>{
					arr.push(item[el.dataIndex])
				})
				list2.push(arr)
			})
			
      const XLSX = require('xlsx')
      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list1), "月度销量")
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list2), "月度销售额")

      XLSX.writeFile(wb, "商品月度销售汇总.xlsx")
    },

    async handlerSuggestGoods (keyword) {
      if(this.timerSuggestGoods){
        clearTimeout(this.timerSuggestGoods)
        this.timerSuggestGoods = null
      }
      this.timerSuggestGoods = setTimeout(()=>{
        this.getSuggestGoods(keyword)
      }, 100)
    },
    async getSuggestGoods(keyword){
      keyword = (keyword || "").replace(/^\s*/g,"").replace(/\s*$/g,"")
      if(!keyword){
        this.suggestGoodsList = []
        return
      }
      const { data, code } = await getGoodsList({
        goods_name: keyword,
        status: 1,
        page_count: 10,
      })
      if (code === 0) {
        this.suggestGoodsList = data.list
      }
    },
    handlerGoodsChanged(val){
    	val.filter(id=>{
    		const item = this.suggestGoodsList.find(el=>el.goods_id==id)
    		if(item){
    			if(this.search_goods_list.findIndex(el=>el.goods_id==id) == -1){
    				this.search_goods_list.push({
    					goods_id: item.goods_id,
    					goods_name: item.goods_name,
    				})
    			}
    		}
    	})
    	this.search_goods_list = this.search_goods_list.filter(el=>val.includes(el.goods_id))
    },

    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style lang="less">
.analy-tips{
	margin: 10px 0;
	color: #aaa;
	font-size: 12px;
}
.no-data{
	text-align: center;
  padding: 40px;
}
.goods-table{
	padding-top: 20px;
	.goods-left{
		padding: 10px;
		height: 100%;
		width: 300px;
		border-right: 1px solid #ccc;
		.search-item{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			.label{
				width: 50px;
				min-width: 50px;
			}
		}
	}
}
</style>