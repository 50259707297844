import ajax from '@/utils/ajax.js'

/**
 * 待交付的产品
 * @param {*} params 
 * @returns 
 */
export function getGoodsTobeDeliver(params) {
  return ajax.post('/RegionalStatisticsAllPermission/toBeContinuedGoods',params)
}

/**
 * 产品销售统计
 * @param {*} params 
 * @returns 
 */
export function getGoodsSaled(params) {
  return ajax.post('/RegionalStatisticsAllPermission/orderGoodsOverview',params)
}

/**
 * 产品销售统计（每日）
 * @param {*} params 
 * @returns 
 */
export function getGoodsDailySaled(params) {
  return ajax.post('/RegionalStatisticsAllPermission/orderGoodsOverview2',params)
}

/**
 * 产品销售明细：导出某产品的销售明细（主要是特殊商品）
 * @param {*} params 
 * @returns 
 */
export function exportGoodSaledList(params) {
  return ajax.openurl('/RegionalStatisticsExport/orderToEachGoods',params)
}


/**
 * 城市门店补货提醒
 * @param {*} params 
 * @returns 
 */
export function getStockRemind(params) {
  return ajax.post('/RegionalStatisticsAllPermission/outStockRemind',params)
}

